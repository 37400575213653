import * as React from "react"
import YachtDetails from "../../components/fleet/yachtDetails"
import YachtIntro from "../../components/fleet/yachtIntro"
import { Footer } from "../../components/footer"
import { NavBar } from "../../components/header"
import Layout from "../../components/layout"
import Newsletter from "../../components/newsletter"
import Seo from "../../components/seo"
import FleetSwiper from "../../components/fleetSwiper"
// import YachtSwiper from "../../components/fleet/yachtSwiper"
import Faq from "../../components/faq"
import YachtAvailibility from "../../components/fleet/yachtAvailibility"
import moment from "moment"
import fetchQuery from "../../utils/fetchQuery"
import { BOOKING } from "../../utils/constants"
import { graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Viper = () => {
    const allYachtsData = useStaticQuery(graphql`
        query YachtDataViper {
            allYachtsJson(filter: { name: { ne: "default" } }) {
                nodes {
                    id
                    name
                    specification {
                        length
                        beam
                        draft
                        fuel
                        speed
                        berths
                    }
                    model
                    link
                    description {
                        first
                        second
                    }
                    features {
                        first
                        second
                    }
                    mainImage {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                    images {
                        childImageSharp {
                            id
                            gatsbyImageData
                        }
                    }
                    type
                }
            }
        }
    `)
    const allYachts = allYachtsData.allYachtsJson.nodes

    const [yachtData, setYachtData] = React.useState(allYachts.find(y => y.name === "Viper"))
    const others = allYachts.filter(y => y.name !== "Viper")

    const getYachtData = React.useCallback(async () => {
        await fetchQuery({
            method: "GET",
            endPoint: `configuration/Vessel/GetVesselByName?vesselName=Viper`,
            successCallback: response => {
                setYachtData(y => ({ ...response, ...y, netId: response.id }))
            }
        })
    }, [])

    const getPriceFrom = React.useCallback(async () => {
        const currentDate = moment().format("YYYY-MM-DD")

        await fetchQuery({
            method: "GET",
            endPoint: `configuration/Vessel/GetLowestVesselRentalPriceWithinRequestedMonth?vesselId=${yachtData.netId}&date=${currentDate}`,
            successCallback: response => {
                setYachtData(y => ({ ...y, lowestPrice: response }))
            }
        })
    }, [yachtData.netId])

    React.useEffect(() => {
        getYachtData()
    }, [getYachtData])

    React.useEffect(() => {
        if (yachtData.netId) getPriceFrom()
    }, [yachtData.netId, getPriceFrom])

    return (
        <Layout>
            <Seo title="Viper" />

            <div className="top-gradient-container">
                <NavBar image />
                <StaticImage className="background-yacht-water d-none d-md-block" src="../../images/water1.png" alt="water" />
                <YachtIntro yachtData={yachtData} />
                <YachtDetails yachtData={yachtData} />
            </div>

            {/* {BOOKING && (
                <div className="overflow-x-hidden">
                    <YachtSwiper images={yachtData.images} />
                </div>
            )} */}
            {BOOKING && <YachtAvailibility vesselCapacity={yachtData?.capacity} vesselName="Viper" vesselId={yachtData?.netId} />}
            {BOOKING && <Faq />}
            <div className="overflow-x-hidden">
                <FleetSwiper yachts={others} />
            </div>

            <Newsletter />
            <Footer />
        </Layout>
    )
}

export default Viper
